/**======================================================================
=========================================================================
Template Name: Able Pro Admin Template
Author: Phoenixcoded
supports: https://phoenixcoded.ticksy.com
File: style.css
=========================================================================
=========================================================================
  == Table of Contents==
	- Generic classes
		-	Margin, Padding, Font class, text align, position, floating, overflow, background class, text color, display class, boarder class
	- Theme Elements
		-	Accordion, Button, tabs, typography, buttons, box shadow, Lable & Badges, Alert, Pagination, Breadcumb, Cards, Collapse,
        -   Carousel, Grid, Progress, Model, tooltip, popover, Datepicker, Gridstack, lightbox, notification, Nestable, pnotify, rating,
        -   Rangeslider, Slider, Syntax Highlighter, Tour, Treeview, Toolbar, Session Timeout, Session idle Timeout, offline, Animation
    - Forms
        -   Forms Elements, Advance Form Control, Validation, Masking, Wizard, Picker, Select
	- Pages
		-   authentication, Maintenance, Maps, Landing page messages, task, Todo, Notes, Charts, Icons, Gallery, Editors,
        -   Invoice, Full Calender, File Upload,
=================================================================================
=================================================================================== */

// Theme Font
@import 'themes/font/theme-font';

// theme scrollbar
@import 'themes/plugin/perfect-scrollbar';

// main framework
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import 'settings/custom-variables';
@import 'settings/theme-variables';

// ng module
@import 'react-module/react-module';

// bootstrap
@import '~bootstrap/scss/bootstrap';

// icon
@import 'themes/font/next-icon';
@import 'themes/font/feather';
@import 'themes/font/fontawesome';
@import 'themes/font/flaticon';
@import 'themes/font/cryptofont';

// bootstrap css
@import 'themes/bootstrap-overlay/card';
@import 'themes/bootstrap-overlay/button';
@import 'themes/bootstrap-overlay/radiobox-checkbox';
@import 'themes/bootstrap-overlay/switches';
@import 'themes/bootstrap-overlay/badge';

@import 'themes/general';
@import 'themes/generic';
@import 'themes/plugin/animate';

// theme layouts
@import 'themes/layouts/menu-lite';
@import 'themes/layouts/menu-react';
@import 'layout-dark';
@import 'layout-rtl';

// Dashboard Widgets
@import 'themes/dashboard/widget';

// custom plugin
@import 'themes/plugin/sweatalert';
@import 'themes/plugin/lightbox';
@import 'themes/plugin/notification';
@import 'themes/plugin/pnotify';
@import 'themes/plugin/bar-rating';
@import 'themes/plugin/rangeslider';
@import 'themes/plugin/daterange';
@import 'themes/plugin/tags-input';
@import 'themes/plugin/maxlength';
@import 'themes/plugin/wizard';
@import 'themes/plugin/select2';
@import 'themes/plugin/data-tables';

// Charts
@import 'themes/plugin/highcharts';
@import 'themes/plugin/peitychart';

// pages
@import 'themes/pages/authentication';
@import 'themes/pages/maintaince';
@import 'themes/pages/pages';
@import 'themes/pages/help-desk';
@import 'themes/pages/fullcalender';
@import 'themes/pages/icon-lauouts';
@import 'themes/pages/user';
@import 'themes/pages/task-board';
@import 'themes/pages/masonry-gallery';

// custom
@import 'themes/custom';
@import 'chat.min';

//// Slick
@import '../../../node_modules/slick-carousel/slick/slick.scss';
@import '../../../node_modules/slick-carousel/slick/slick-theme.scss';

@font-face {
  font-family: 'Mitr';
  font-weight: normal;
  font-style: normal;
  src: url(../fonts/Prompt-Regular.ttf);
  //src: url(../fonts/Mitr-Bold.ttf);
  //src: url(../fonts/Mitr-Italic.ttf);
  //src: url(../fonts/Mitr-Light.ttf);
  //  src: url(../fonts/Mitr-Medium.ttf);
}

@for $i from 1 through 100 {
  $zIndexBackdrop: #{1020 + (5 * $i)};
  $zIndexContent: #{1020 + (5 * $i) + 2};

  .modal-backdrop.show:nth-of-type(#{$i}) {
    z-index: $zIndexBackdrop;
  }

  div[role='dialog'][aria-modal='true']:nth-of-type(#{$i}) {
    z-index: $zIndexContent;
  }
}

.text {
  &-cyan {
    color: $cyan;
  }

  &-gray {
    color: $gray-400;
  }
}

.ReactModal__Overlay {
  z-index: 1100 !important;
}

.ReactModal__Overlay:has(.treatment-image-history-lightbox-preview) {
  z-index: 99999 !important;
}

.flex-1 {
  flex: 1;
}

@font-face {
  font-family: 'Mitr';
  font-weight: bold;
  font-style: normal;
  src: url(../fonts/Prompt-SemiBold.ttf);
}

$hd-height: 70px;
$hd-height-small-screen: 50px;
$nav-width: 190px;
$silver: #d4daf0;

$bookingHeight: 250px;

body {
  font-family: 'Mitr' !important;
  font-size: 15px;
  background-color: $gray-200;
}

label {
  font-family: 'Mitr' !important;
}

ul {
  padding-inline-start: 15px;
}

p {
  font-size: 15px !important;
  word-break: break-word;
}

.calendar-container thead {
  position: relative;
  z-index: 4;
}

.calendar-container thead {
  position: relative;
  z-index: 4;
}

.calendar-container tbody {
  position: relative;
  z-index: 3;
}

.image-cover {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  &::before {
    content: '';
    float: left;
    padding-top: 100%;
  }

  &::after {
    content: '';
    display: block;
    clear: both;
  }
}

.btn-light {
  background-color: $white;
  border-color: $black;
  color: $black;

  &:hover {
    background-color: $white;
    border-color: $black;
  }
}

.card .card-header h5 {
  font-size: 1.25rem;
}

.bg-container {
  background-color: transparent;
  resize: none;

  &-dark {
    color: white;

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: white;
      opacity: 1;
      /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: white;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: white;
    }
  }

  &-light {
    color: black;

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: black;
      opacity: 1;
      /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: black;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: black;
    }
  }
}

.signature-tabs {
  .card-header-tabs {
    margin-bottom: 0;
  }
}

.absolute-fill {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.calendar {
  .table-responsive {
    //overflow-y: auto;
    //max-height: calc(90vh - 100px - 3rem);
  }
}

.calendar-container {
  height: calc(100vh - 330px);

  .table-responsive {
    height: 100%;
    padding-left: 10px;

    tbody {
      th {
        z-index: 1000 !important;
      }
    }

    .tr-chair {
      th {
        position: sticky;
        top: 0;
        background-color: inherit;
        z-index: 1000;
      }
    }
  }
}

.virtual-table {
  thead {
    z-index: 4 !important;
  }
}

.table-responsive {
  min-height: 200px;
}

table.follow-up {
  line-height: 1;

  td {
    padding: 0.5rem 0.75rem;
  }
}

.in-service {
  background-color: #ffdae6;
}

.table.borderless {
  th,
  td {
    border-top: 0;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
  }

  tbody {
    padding-top: 1rem;

    tr:first-child td {
      padding-top: 1.5rem;
    }
  }

  thead th {
    background-color: transparent;
    border-bottom: 2px solid $primary;
    color: $primary;
  }
}

.profile-image {
  background: url(https://gravatar.com/avatar?s=200&amp;d=mp) no-repeat center
    center / cover;
  border-radius: 50%;
}

.hide-caret {
  &::after {
    display: none;
  }
}

.hide-modal {
  overflow: auto !important;
}

//react-select
input[id^='react-select'] {
  min-width: 100px !important;
}

div[class^='css'][class$='Input'] {
  position: relative;
  z-index: 99;
}

div[class^=' css'][class$='menu'] {
  z-index: 100;
}

.react-datepicker-popper {
  z-index: 100;
}

div[class$='singleValue'],
div[class$='placeholder'] {
  pointer-events: none;
}

//Date Picker
.react-datepicker__input-container .form-control[readonly] {
  background-color: $white !important;
}

.react-datepicker__input-container .form-control[disabled] {
  background-color: $gray-200 !important;
}

.react-datepicker__day--today {
  color: $secondary;

  &.react-datepicker__day--selected {
    color: $white;
  }
}

//Chat Room
.gx-chat-item {
  align-items: flex-end;

  &.gx-flex-row-reverse {
    .gx-bubble {
      background-color: $primary !important;
      color: $white;
    }
  }

  .gx-bubble-block {
    width: auto !important;
  }

  .gx-time {
    margin-bottom: 0.5rem;
  }
}

#chat a {
  color: inherit;
}

#chat {
  .gx-chat-sidenav-scroll-tab-1,
  .gx-chat-sidenav-scroll-tab-2 {
    height: 100% !important;
  }
}

#chat .gx-chat-list-scroll {
  @media (max-width: 991px) {
    height: 70vh !important;
  }

  @media (min-width: 992px) {
    height: calc(100vh - 300px) !important;
  }
}

//Sales table
.sales-table {
  max-height: 80vh;
  min-height: 200px;
  overflow: auto;
  display: block;

  thead th {
    position: sticky;
    top: 0;
    z-index: 1;
  }
}

//NavBar
a.dropdown-item {
  color: $black;

  &.active,
  &:hover {
    color: $white !important;
  }
}

.invitation-container {
  position: absolute;
  bottom: $hd-height;
  left: 0;
  right: 0;

  @media (max-height: 680px) {
    bottom: $hd-height-small-screen;
  }
}

.pcoded-navbar {
  width: $nav-width !important;
  background-color: $primary !important;
  color: white !important;
  margin-top: 0 !important;
  top: $hd-height !important;

  @media (max-height: 680px) {
    top: $hd-height-small-screen !important;
  }

  left: 0;
  position: fixed !important;

  a {
    color: white !important;
  }

  .pcoded-inner-navbar > li > a {
    margin: 10px 0px 5px 10px !important;

    @media (max-height: 680px) {
      padding: 3px 10px;
    }

    &.active {
      background-color: $secondary !important;
      box-shadow: none !important;
      border-radius: 2rem 0 0 2rem !important;
    }
  }
}

.mob-toggler,
.mobile-menu {
  height: $hd-height !important;
}

.pcoded-header {
  position: fixed !important;
  top: 0;
  left: 0;
  z-index: 99;
  height: $hd-height !important;
  background-color: $white !important;
  color: black !important;

  .dropdown-toggle {
    width: auto !important;
    line-height: normal !important;
  }

  .navbar-collapse {
    flex: 1;
  }

  .navbar-nav {
    @media (max-width: 991px) {
      flex: 1;
      flex-wrap: wrap;

      &.ml-auto {
        height: auto;
      }
    }

    .search {
      flex-wrap: nowrap;

      .input-group-text {
        background-color: $gray-200 !important;
        border: 0 !important;
        border-radius: 5px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;

        i {
          color: $gray-500 !important;
        }

        padding: 0 !important;
        padding-left: 0.75rem !important;
      }

      .form-control {
        height: 40px !important;
        width: 180px;

        @media (min-width: 1200px) {
          width: 300px;
        }

        @media (min-width: 1399px) {
          width: 400px;
        }

        background-color: $gray-200 !important;
        border: 0 !important;

        &::placeholder {
          /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: $gray-500 !important;
          opacity: 1;
          /* Firefox */
        }

        &:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: $gray-500 !important;
        }

        &::-ms-input-placeholder {
          /* Microsoft Edge */
          color: $gray-500 !important;
        }
      }
    }

    .dropdown {
      height: auto !important;
    }

    > li {
      &:first-child {
        padding-left: 0px;
      }

      line-height: normal !important;

      margin-left: 1rem;

      @media (min-width: 1399px) {
        margin-left: 1.5rem;
      }

      @media (max-width: 991px) {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
      }

      .lang-icon {
        width: 25px;
        height: 25px;
      }
    }
  }

  .btn > i {
    margin-right: 0px !important;
  }

  .pro-head {
    img {
      width: 50px;
      height: 50px;
    }
  }
}

.search-white {
  .input-group-append {
    .input-group-text {
      padding: 0 0.75rem !important;
    }
  }

  .input-group-text {
    background-color: $white !important;
    border: 0 !important;
    border-radius: 5px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;

    i {
      color: $gray-500 !important;
    }
  }

  .form-control {
    height: 40px !important;
    background-color: $white !important;
    border: 0 !important;

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $gray-500 !important;
      opacity: 1;
      /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: $gray-500 !important;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: $gray-500 !important;
    }
  }
}

.register {
  .input-group-append {
    .input-group-text {
      padding: 0 0.75rem !important;
    }
  }

  .input-group-text {
    background-color: $gray-100 !important;
    border: 0 !important;
    border-radius: 5px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;

    i {
      color: $gray-500 !important;
    }
  }

  .form-control {
    height: 60px !important;
    background-color: $gray-100 !important;
    border: 0 !important;

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $gray-500 !important;
      opacity: 1;
      /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: $gray-500 !important;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: $gray-500 !important;
    }
  }
}

.pcoded-header {
  .m-header {
    height: 70px;

    @media (min-width: 992px) {
      width: $nav-width;
      height: $hd-height;
    }

    background-color: $primary;
    overflow: hidden;

    .mobile-menu {
      @media (min-width: 992px) {
        display: none;
      }
    }
  }
}

// For laptop
@media (min-width: 1300px) {
  body.sub-menu-opened {
    .pcoded-content {
      padding-left: 225px;
    }
  }

  body.sub-menu-right-opened {
    .pcoded-content {
      padding-right: 225px;
    }
  }
}

.sidemenu li {
  list-style: none;
}

.pcoded-main-container {
  margin-left: $nav-width;
}

#main-logo {
  width: 130px;
}

.pcoded-header[class*='header-'] .btn-link {
  color: black !important;
}

.pcoded-wrapper {
  padding-top: $hd-height;
}

//Override theme
.review-card .review-block > div:hover {
  background-color: inherit !important;
}

.pcoded-header .dropdown .notification .noti-head {
  background-color: white;
  border-bottom: 2px solid $primary;

  h5 {
    color: $primary;
  }
}

.btn {
  border-radius: 8px;

  &-2row {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  &.btn-lg {
    border-radius: 12px;
  }
}

.table {
  .dropdown-toggle {
    padding: 0.1rem 0.7rem !important;
    width: 105px !important;

    &.daily-sale-state {
      width: 120px !important;
    }

    color: black;

    &.btn-light {
      border: 2px solid $gray-400;
    }

    &.btn-info {
      border: 2px solid $info;
      background-color: #d4daf1 !important;
    }

    &.btn-warning {
      border: 2px solid $warning;
      background-color: #f8bd68 !important;
    }

    &.btn-success {
      border: 2px solid $success;
      background-color: #b5e8a1 !important;
    }
  }
}

.dropdown-toggle {
  border-radius: 10px !important;
}

.datetime .react-datepicker-wrapper {
  display: block !important;
}

.form-control {
  border: 1px solid $gray-300 !important;
  padding: 0.625rem 0.75rem !important;
  border-radius: 5px;

  &:focus {
    background-image: none !important;
  }
}

.child-w-100 > * {
  width: 100%;
}

.custom-control-input {
  z-index: 1;
}

//Common
.text-large {
  font-size: 125%;
}

.text-largeer {
  font-size: 150%;
}

.box-shadow {
  -webkit-box-shadow: 3px 3px 5px 0px rgba(222, 226, 230, 1);
  -moz-box-shadow: 3px 3px 5px 0px rgba(222, 226, 230, 1);
  box-shadow: 3px 3px 5px 0px rgba(222, 226, 230, 1);
  background-color: $white;

  &-dark {
    -webkit-box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.65);
    -moz-box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.65);
    box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.65);
  }
}

.pinned-container {
  position: absolute;
  top: 5px;
  right: 40px;
  width: 25px;
  height: 25px;
}

.more-action-button-container {
  position: absolute;
  top: 5px;
  right: 10px;
}

.row-edge-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.row-edge {
  display: flex;
  justify-content: space-between;
}

.icon-container {
  border-radius: 8px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.modal-title {
  color: $white;
}

.underline {
  text-decoration: underline !important;
  cursor: pointer;
}

.link {
  text-decoration: underline !important;
  cursor: pointer;
  color: $primary !important;
}

.custom-file-input-container {
  position: relative;

  & input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 19;
  }
}

.row-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.row-vcenter {
  display: flex;
  align-items: center;
}

.column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.bg-disabled {
  background-color: $gray-200;
}

.none-carat .dropdown-toggle {
  &::after {
    display: none;
  }

  &::before {
    display: none;
  }
}

//Dashboard
.card-stats {
  height: 300px;

  @media (min-width: 1400px) {
    height: 260px;
  }
}

.slick-slide {
  padding-right: 15px;

  .dashboard-stats-item {
    height: 300px;
    width: 400px;
  }
}

//Setting
.secondary-radio {
  .custom-control-input:checked ~ .custom-control-label::before {
    background-color: $secondary;
    border-color: $secondary;
  }
}

//SideMenu
.float-left.accordion {
  position: fixed;
  padding-right: 1rem;
  z-index: 99;
  left: 0;

  @media (min-width: 992px) {
    left: $nav-width;
  }
}

.calendar.accordion {
  position: fixed;
  // z-index: 99;
  right: 0;

  .side + .btn {
    padding: 0 !important;
    position: absolute;
    top: 300px;
    left: -150px;
  }

  .btn-link {
    &:hover {
      text-decoration: none;
    }

    &:focus {
      text-decoration: none;
    }
  }

  &.follow-top-btn {
    .side + .btn {
      top: 20px;
    }
  }
}

.calendar {
  .sidemenu {
    background-color: $gray-100;
    padding: 20px 10px;
    width: 450px;
    position: relative;
    height: 100vh;

    &-button {
      &-expanded {
        right: -38px;
        top: -10px;
        left: auto;
      }

      &-icon-expanded {
        width: 150px;
        height: 40px;
        border-radius: 0;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        background-color: $gray-100;
      }

      i {
        font-size: 1.5rem;
      }
    }
  }
}

.collapsing {
  &.side {
    transition-property: none;
    overflow: visible;
    transition: none;
  }
}

.sidemenu {
  background-color: $gray-200;

  @media (max-width: 1299px) {
    background-color: rgba(233, 236, 239, 0.95);
  }

  padding: 10px 15px;
  width: 200px;
  position: relative;
  height: 100vh;

  &-button {
    position: absolute;
    left: -20px;
    top: -10px;
    z-index: 98;

    &-expanded {
      right: -38px;
      top: -10px;
      left: auto;
    }

    &-icon-collapsed {
      width: 40px;
      height: 40px;
      border-top-right-radius: 50%;
      border-bottom-right-radius: 50%;
      background-color: $secondary;
    }

    &-icon-expanded {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: $secondary;
    }

    i {
      font-size: 1.5rem;
    }
  }
}

.accordion {
  .side.show + div,
  .side.collapsing + div {
    display: none;
  }
}

//Header
.page-header {
  display: none;
}

.pcoded-content {
  margin-top: 0 !important;
  padding-left: 50px;
}

//Calendar
.open-booking-slot {
  background-color: $gray-400 !important;
  border-color: $gray-400 !important;
  color: $black !important;

  &:hover {
    background-color: $primary !important;
    color: $white !important;
  }
}

.less-line-height {
  line-height: 1.1;
}

.booking-table {
  td,
  th {
    white-space: normal;
  }
}

.tr-chair td:not(:last-child) {
  border: 0;
}

.tr-chair td:last-child {
  border-left-color: transparent;
  border-top-color: transparent;
  border-right-color: transparent;
}

.booking-cell {
  height: $bookingHeight;
  padding: 0 !important;
  position: relative;
  border-top: 1px solid $gray-500;
}

.stylist-cell {
  vertical-align: middle !important;
}

.close {
  color: $white !important;
  opacity: 1 !important;
}

.white-modal-header {
  .close {
    color: $black !important;
    opacity: 1 !important;
  }
}

.booking-container {
  position: absolute;
  left: 0;
  right: 0;
  padding: 5px;
  font-size: small;
  overflow: hidden;
  border: 1px #e8e3fe solid;
  z-index: 2;

  &:hover:not(.disabled) {
    z-index: 3;
    border-color: #5f28cd;
  }

  &.dragging {
    max-height: $bookingHeight !important;
  }
}

.chair-cell {
  padding: 0 !important;
  vertical-align: bottom !important;
}

.chair-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: '100%';
}

.chair-item {
  background-color: $primary;
  color: white;
  padding: 10px 20px;
}

.report-list thead th {
  color: $primary !important;
}

//User List
.user-list thead th {
  background-color: $primary;
  border: 0;
  color: $white !important;
}

.inventory-dashboard thead th {
  background-color: $secondary !important;
}

.user-list thead th:first-child {
  border-top-left-radius: 0.5rem;
}

.user-list thead th:last-child {
  border-top-right-radius: 0.5rem;
}

//Chat
.gx-chat-textarea {
  width: 100%;
}

#chat .gx-chat-date {
  text-align: center;
  margin-top: 5px;
}

body.chat {
  overflow: hidden;
  position: relative;
}

#chat {
  .gx-chat-info {
    min-width: 1px;
  }

  .gx-status-pos .gx-status {
    top: auto !important;
    left: auto !important;
    right: 4px !important;
    bottom: 2px !important;
    width: 12px !important;
    height: 12px !important;
  }
}

//My Business
.my-business-upload {
  border: 1px solid $gray-200;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  maxwidth: 100%;
  height: 200px;
}

// CRM - Nav
.customer-nav {
  .nav-link.active {
    background-color: $white;
    border-left: 8px solid $primary;
    color: $primary;
  }

  .nav-link:not(.active) {
    padding-left: calc(7px + 1rem);
  }

  .nav-link {
    color: $black;
    border-right: 1px solid $gray-200;
    border-left: 1px solid $gray-200;
    border-top: 1px solid $gray-200;

    &:last-child {
      border-bottom: 1px solid $gray-200;
    }
  }
}

// CRM - History

// CRM - Gallery
.gallery-item {
  width: 32%;
  height: 0;
  padding-top: 32%;
  margin-bottom: 2%;
  margin-right: 2%;
  position: relative;

  @media (min-width: 768px) {
    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  @media (max-width: 767px) {
    width: 48%;

    &:nth-child(2n) {
      margin-right: 0;
    }
  }

  &-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.5);
  }
}

// CRM - Reward
.reward-card {
  max-width: 80%;
  width: 500px;

  &-header {
    background-color: $primary;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 1rem;
  }

  &-stamp {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 0.5rem;
    background-color: $gray-300;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    img {
      width: 17%;
      margin-bottom: 1%;
    }
  }
}

// CRM - Coupon
.coupon-card {
  width: 320px;

  &-small {
    & *:not(h3, h4) {
      font-size: 0.9em !important;
    }

    small {
      font-size: 0.75em !important;
    }

    h3 {
      font-size: 1rem !important;
    }

    width: 100%;
    //transform: scale(0.8);
  }

  &-header {
    background-color: $primary;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 0.5rem;
  }
}

// CRM - Stylists
.stylist-item {
  -webkit-box-shadow: 7px 4px 12px -11px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 7px 4px 12px -11px rgba(0, 0, 0, 0.5);
  box-shadow: 7px 4px 12px -11px rgba(0, 0, 0, 0.5);
  border: 2px solid $gray-200;
  border-radius: 5px;
  width: 32%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1rem;
  padding-top: 1rem;
  padding-bottom: 3rem;
  margin-bottom: 2%;
  margin-right: 2%;
  position: relative;
  //z-index: 0;

  @media (min-width: 768px) {
    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  @media (max-width: 767px) {
    width: 48%;

    &:nth-child(2n) {
      margin-right: 0;
    }
  }

  &-bottom-bar {
    align-self: stretch;
    display: flex;
    background-color: $gray-200;
    left: 0;
    bottom: 0;
    width: 100%;
    position: absolute;
    margin-top: 0.5rem;
    padding: 0.5rem 0;

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      cursor: pointer;
      color: $primary;

      &:first-child {
        border-right: 1px solid $gray-400;
      }
    }
  }

  .phone-no-container {
    display: flex;
    align-items: center;
    background-color: $gray-300;
    padding: 0.5rem 1rem;
    margin: 0.5rem 0;
    border-radius: 10px;
    color: $primary;
  }

  .ribbon {
    position: absolute;
    left: 0;
    top: 15px;
    height: 30px;
    display: flex;
    align-items: center;
    padding: 0 8px;
    background-color: $gray-300;
    font-size: 0.75rem;

    &:before {
      content: '';
      position: absolute;
      z-index: 2;
      right: -12px;
      border-top: 15px solid $gray-300;
      border-bottom: 15px solid $gray-300;
      border-right: 12px solid transparent;
    }
  }
}

//Payment
.day-container {
  border-right: 1px solid $primary;

  &:last-child {
    border-right: 0;
  }

  &-selected {
    border-right: 1px solid $white;
  }

  color: $black;
}

//Register

.full-screen {
  .container {
    padding-left: 15px;
    padding-right: 15px;
  }

  min-height: 100vh;

  @supports (-webkit-touch-callout: none) {
    &.modal {
      /* CSS specific to iOS devices */
      min-height: auto;
    }
  }

  @media only screen and (device-width: 768px) {
    &.modal {
      /* CSS specific to iOS devices */
      min-height: auto;
    }
  }

  min-width: 99vw;

  padding: 0 !important;

  .modal-dialog {
    max-width: 100%;
    min-height: 98vh;
    display: flex;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    padding: 0;
  }
}

.register {
  &-process-indicator {
    background-color: $primary;

    @media (min-width: 992px) {
      height: 100vh;
    }

    &-icon {
      font-size: 2rem;
      margin: 2rem 0;

      @media (min-width: 992px) {
        font-size: 5rem;
        margin: 5rem 0;
      }
    }

    &-bar {
      width: 60px;
      height: 3px;
      background-color: rgba(255, 255, 255, 0.35);
      border-radius: 5px;

      &.active {
        background-color: $white;
      }
    }
  }
}

.role-selector {
  width: 300px;
  max-width: 100%;
  height: 100px;

  &-active {
    border: 1px solid $primary;
    background-color: #f5f1fb;
  }
}

.addtional-selector {
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: flex;
  flex: 1;

  &-container {
    margin-top: 1rem;
    display: flex;
    width: 340px;
    max-width: 100%;
    height: 50px;
  }

  &-active {
    border-left: 1px solid $primary;
    border-right: 1px solid $primary;
    background-color: #f5f1fb;
  }
}

.swal2-container {
  z-index: 2000 !important;
}

.salary-list-nav {
  .nav-link.active {
    background-color: $silver;
    color: $primary;
  }

  .nav-link {
    color: $black;
    background-color: $gray-200;
  }
}

// Product Qr Code
.qr-item-container {
  cursor: pointer;
  border: 1px solid $gray-300;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  font-size: 0.8rem;

  &.selected {
    border-color: $success;
  }

  &.size {
    min-width: 110px;
  }

  &.additional {
    min-width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.symbol {
    min-width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  padding: 4px 8px;
}

.product-symbol {
  .fa-circle {
    color: $primary;
  }

  .fa-play {
    color: $secondary;
  }

  .fa-square {
    color: $warning;
  }

  .fa-certificate {
    color: $primary;
  }

  .fa-star {
    color: $info;
  }

  .fa-heart {
    color: $success;
  }
}

i {
  &.size-lg {
    font-size: 1.8rem;
  }

  &.size-md {
    font-size: 1.5rem;
  }

  &.size-sm {
    font-size: 1rem;
  }
}

.shape {
  width: 40px;
  height: 40px;

  &.circle {
    background: $secondary;
    border-radius: 50%;
  }

  &.triangle {
    background: transparent;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 40px solid $primary;
  }

  &.diamond {
    background: transparent;
    width: 0;
    height: 0;
    border: 20px solid transparent;
    border-bottom-color: $success;
    position: relative;
    top: -20px;

    &:after {
      content: '';
      position: absolute;
      left: -20px;
      top: 20px;
      width: 0;
      height: 0;
      border: 20px solid transparent;
      border-top-color: $success;
    }
  }

  &.pentagon {
    background: transparent;
    position: relative;
    width: 27px;
    top: 6px;
    height: auto;
    box-sizing: content-box;
    border-width: 25px 9px 0;
    border-style: solid;
    border-color: $warning transparent;

    &:before {
      content: '';
      position: absolute;
      height: 0;
      width: 0;
      top: -42px;
      left: -9px;
      border-width: 0 22.5px 17.5px;
      border-style: solid;
      border-color: transparent transparent $warning;
    }
  }

  &.hexagon {
    background: $secondary;
    width: 40px;
    height: 20px;
    background: $secondary;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: -12px;
      left: 0;
      width: 0;
      height: 0;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 12px solid $secondary;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: -12px;
      left: 0;
      width: 0;
      height: 0;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-top: 12px solid $secondary;
    }
  }

  &.octagon {
    background: $primary;
    position: relative;
    width: 40px;
    height: 40px;

    &:before {
      content: '';
      width: 40px;
      height: 0;
      position: absolute;
      top: 0;
      left: 0;
      border-bottom: 12px solid $primary;
      border-left: 12px solid white;
      border-right: 12px solid white;
    }

    &:after {
      content: '';
      width: 40px;
      height: 0;
      position: absolute;
      bottom: 0;
      left: 0;
      border-top: 12px solid $primary;
      border-left: 12px solid white;
      border-right: 12px solid white;
    }
  }
}

.w-100 {
  .react-datepicker-wrapper {
    width: 100%;
  }
}

//Account report
.report-separator {
  width: 100%;
  margin: 1rem 0;
  height: 3px;
  background-color: $primary;
  display: block;
  border-radius: 30px;
}

//Filter Warehouse
#slider-round {
  height: 10px;
}

#slider-round .noUi-connect {
  background: $primary;
}

#slider-round .noUi-touch-area {
  background: $primary;
  height: 12px;
  width: 12px;
  border-radius: 10px;
}

#slider-round .noUi-handle {
  height: 12px;
  width: 12px;
  border-radius: 10px;
  right: -9px;
  background: transparent;
}

#slider-round .noUi-handle:after {
  width: 0px;
}

#slider-round .noUi-handle:before {
  width: 0px;
}

.pro-body {
  padding: 20px 0;
  margin-bottom: 0;
  list-style: none;

  li {
    margin: 0 10px;
    border-radius: 2px;

    a {
      color: $theme-font-color;
      font-size: 14px;
      padding: 10px;

      i {
        margin-right: 10px;
      }
    }

    &.active,
    &:active,
    &:focus,
    &:hover {
      background: $primary-color;
      box-shadow: 0 15px 8px -9px rgba(0, 0, 0, 0.25);

      > a {
        color: #fff;
        background: transparent;
      }
    }
  }
}

.code {
  &__hubswitch {
    background: $info;
    border-color: $primary !important;
  }

  &__additional {
    background: #acf6d6;
    border-color: $success !important;
  }

  &__additional_2 {
    background: #ffe4b9;
    border-color: $warning !important;
  }
}

.recall-list {
  padding: 0 5px;

  &:hover {
    background-color: $primary;
    color: $white;
    border-radius: 2px;
  }
}

.recall-delete {
  &:hover {
    color: $red;
  }
}

@import 'variables';
@import 'base';
@import 'teeth-selection-piece';
@import 'common/main';
@import 'component/main';
@import 'crm/main';
@import 'human-resource/main';
@import 'human-resource-report/main';
@import 'list-package/main';
@import 'xray/main';
@import 'inventory/main';
@import 'service/main';
