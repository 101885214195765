.drag-container {
  border: 2px dashed #ccc;
  text-align: center;
  // padding-bottom: 10px;
  padding-right: 10px;
}

.file-item-label {
  background-color: #b894ff80;
  color: $primary;
}

.file-item {
  cursor: pointer;
  border-radius: 8px;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;

  .fa-file,
  .fa-upload {
    font-size: 25px;
  }

  .delete-button {
    background-color: $red;
    color: $white;
    position: absolute;
    bottom: 5px;
    right: 5px;
    width: 25px;
    height: 25px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.file-border {
  border: 1px solid #bac2cb;
}

.file-container {
  width: 100px;
  font-size: 12px;
  margin-top: 10px;
  margin-left: 10px;

  label {
    margin: 0;
  }
}

.file-name {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.empty-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h4 {
    font-size: 18px;
  }

  .empty-detail {
    font-size: 14px;
  }

  .file-container {
    margin-left: 0;
  }
}

.date-input-container {
  border: 1px solid #cccccc;
  height: 38px;
  border-radius: 4px;

  input {
    width: 100%;
    border-width: 0;
  }
}

.filter-label {
  white-space: nowrap;
}

.service-table {
  th {
    background-color: $primary !important;
    color: white !important;
    border-width: 0;
  }

  td {
    border: 0;
    vertical-align: middle;
  }

  tbody {
    tr {
      border-bottom: 2px solid #f6f6f6;

      td {
        background-color: $white;
      }

      &:hover {
        td {
          background-color: #f6f6f6;
        }
      }
    }
  }
}

.service-table-container {
  border-radius: 10px;
  overflow: hidden;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.activity-card {
  border-radius: 10px;
  overflow: hidden;
  background-color: white;
  border: 1px solid #bac2cb;
  padding: 10px 15px;
  margin-bottom: 15px;

  hr {
    margin: 5px 0;
  }

  h4 {
    margin: 0;
    font-size: 20px;
  }

  .image-container {
    width: 50px;
    height: 50px;
    border-radius: 5px;
    overflow: hidden;
  }

  .equipment-container {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
  }

  .timeline-container {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;

    .status {
      font-weight: bold;
    }

    .completed {
      color: #0dad82;
    }
  }
}

.service-filter {
  border-radius: 10px;
  // overflow: hidden;
  background-color: white;
  border: 1px solid #bac2cb;
}

.state-box {
  padding: 0 20px;
  border: 2px solid;
  border-radius: 10px;
}

.service-equipment-form {
  .modal-header .modal-title,
  .close-modal {
    color: black;
  }

  .list-box {
    border: 1px solid #f1f2f4;
    border-radius: 5px;
  }

  .text-link {
    text-decoration: underline;
    cursor: pointer;
    color: $primary;
  }

  .add-service-btn {
    color: $primary;
    cursor: pointer;
  }

  .fa-circle-plus {
    font-size: 20px;
  }

  .modal-footer {
    justify-content: space-between;
    padding: 10px 20px;
  }

  .detail-text {
    padding: 10px;
    border: 1px solid #bac2cb;
    border-radius: 5px;
    background-color: #f1f2f4;
  }

  .bold-text {
    font-weight: bold;
  }

  .extended-button {
    border-color: #ffc56e;
    color: #ffc56e;

    &:hover {
      background-color: #ffc56e;
      color: $white;
    }
  }

  .damaged-equipment {
    border-color: red;
    color: red;

    &:hover {
      background-color: red;
      color: $white;
    }
  }

  .equipment-detail-button {
    background-color: #ffc56e;
    border-color: #ffc56e;
  }
}

.equipment-item {
  border: 1px solid #bac2cb;
  border-radius: 5px;

  .status-dot {
    width: 15px;
    height: 15px;
    min-width: 15px;
    min-height: 15px;
    border-radius: 15px;
  }

  .fa-circle-minus {
    color: #bac2cb;
  }

  .quantity-text {
    color: #bac2cb;
    font-size: 12px;
  }
}

.equipment-detail {
  .equipment-image-box {
    width: 100px;
    height: 100px;
    border: 1px solid #bac2cb;
    border-radius: 5px;
    overflow: hidden;
  }
}

.shipping-costs-modal {
  .qr-wrapper {
    padding: 20px;
    border: 1px solid #707070;
    text-align: center;
    border-radius: 5px;
  }

  .qr-container {
    width: 300px;
    height: 300px;
    // object-fit: contain;
  }

  .slip-conatiner {
    width: 100%;

    img {
      width: 100%;
      height: auto;
    }
  }

  .fa-circle-question {
    color: #d0d0d0;
    position: absolute;
    top: 13px;
    right: -20px;
  }

  .footer {
    margin: auto;
    position: relative;

    button {
      width: 200px;
    }
  }
}

.wrap {
  flex-wrap: wrap;
}

.image-preview-dialog {
  .modal-content {
    background-color: black;
    border-width: 0;
    border-radius: 10px;
    overflow: hidden;
  }

  img {
    max-height: 90vh;
    object-fit: contain;
  }

  .close-modal {
    position: absolute;
    top: 10px;
    right: 15px;
    background-color: #ff5252;
    color: $white;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
  }

  .navigation-btn {
    position: absolute;
    top: 50%;
    background-color: #b894ff;
    color: $primary;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
  }
}

.sticky-right-column {
  position: sticky;
  right: 0;
}

// .bg-white {
//   background-color: $white;
// }

.flex-warp {
  flex-wrap: wrap;
}

.left-border {
  border-left: 1px solid $gray-300;
}

.hover-opacity {
  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.6;
  }
}
